import { isDesktop } from '@configs/devices/device_util';
import { MILLI_SECONDS_OF_ONE_SECOND } from '@constants/milli_seconds';
import { Portal, Snackbar } from '@miri-unicorn/miricanvas-ds';
import { CommonZIndex } from '@styles/CommonZIndex';
import { SAFE_AREA_INSET_TOP } from '@ui/common/_constants/safe_area_inset';
import { useIsMobileSize } from '@ui/common/_hooks/useIsMobileSize';
import { useBoolean } from '@utils/hooks';
import { ComponentProps, ReactNode, createContext, useCallback, useRef, useState } from 'react';
import styled from 'styled-components';

/** TODO DS 에서 export 해야함 */
type SnackbarProps = ComponentProps<typeof Snackbar> & {
  style?: React.CSSProperties;
};

export type OpenSnackbarFn = (props: Omit<SnackbarProps, 'onClose'>) => void;
type CloseSnackbarFn = () => void;

type SnackbarContextValue = {
  openSnackbar: OpenSnackbarFn;
  closeSnackbar: CloseSnackbarFn;
};

export const SnackbarContext = createContext<SnackbarContextValue | null>(null);

type SnackbarProviderProps = {
  children: ReactNode;
};

/**
 *  @todo
 *   _app 에 깔려 , 내부의 state 가 바뀌면 전체가 리렌더링 되는 현상을 최적화 할 필요가 있음
 */
export const SnackbarProvider = ({ children }: SnackbarProviderProps) => {
  const { value: isOpen, setTrue: setOpen, setFalse: closeSnackbar } = useBoolean();
  const timerRef = useRef<ReturnType<typeof setTimeout> | undefined>();

  const [snackbarProps, setSnackbarProps] = useState<SnackbarProps>({
    onClose: closeSnackbar,
  });

  const { isMobileSize } = useIsMobileSize();

  const openSnackbar = useCallback(
    (props: Omit<SnackbarProps, 'onClose'>) => {
      setOpen();
      setSnackbarProps({
        ...props,
        onClose: closeSnackbar,
        widthType: isMobileSize ? 'fill' : props.widthType,
      });

      clearTimeout(timerRef.current);
      timerRef.current = setTimeout(() => {
        closeSnackbar();
      }, MILLI_SECONDS_OF_ONE_SECOND * 5);
    },
    [closeSnackbar, isMobileSize, setOpen]
  );

  return (
    <SnackbarContext.Provider value={{ openSnackbar, closeSnackbar }}>
      {isOpen && (
        <Portal>
          {isDesktop() ? (
            <SnackbarDesktopWrapper style={snackbarProps.style}>
              <Snackbar {...snackbarProps} />
            </SnackbarDesktopWrapper>
          ) : (
            <SnackbarMobileTabletWrapper style={snackbarProps.style}>
              <Snackbar {...snackbarProps} shape='square' widthType='fill' />
            </SnackbarMobileTabletWrapper>
          )}
        </Portal>
      )}
      {children}
    </SnackbarContext.Provider>
  );
};

const SnackbarDesktopWrapper = styled.div`
  position: fixed;
  /* 헤더바 (60px) 에서 떨어진 위치 24px .. TODO */
  top: 84px;
  left: 50%;
  transform: translate(-50%);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: ${CommonZIndex.SNACK_BAR};
`;

const SnackbarMobileTabletWrapper = styled(SnackbarDesktopWrapper)`
  top: calc(${SAFE_AREA_INSET_TOP} + 20px);
`;
