import { useRef } from 'react';
import { useIsomorphicLayoutEffect } from '@utils/hooks';

export const RecruitmentNotice = () => {
  const hasRun = useRef(false);

  useIsomorphicLayoutEffect(() => {
    if (!hasRun.current) {
      hasRun.current = true;

      setTimeout(function () {
        console.group(
          '%c안녕하세요! 👋 ',
          'font-size: 20px; font-weight: bold;  color: #21afbf; font-family: monospace;'
        );
        // eslint-disable-next-line
        console.log(
          '⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⣤⡶⢟⢛⢋⢛⢛⠶⣦⡀⠀⠀⠀⠀⠀⠀⠀⠀⣽⡿⣽⢯⡿⣽⢯⡿⣽⣳⡘⢋⢼⣝\n' +
            '⠀⠀⠀⠀⠀⠀⠀⢀⣤⡴⡶⢶⢤⣤⡀⠀⢀⣾⠫⡐⢅⠪⣰⣵⠦⡑⢌⠿⣆⠀⠀⠀⠀⠀⠀⠀⢾⡿⣽⢯⢿⡽⡯⣿⢽⣚⣙⣘⣋⣳\n' +
            '⠀⠀⠀⠀⠀⢀⣼⠫⡑⠔⢌⠢⡑⢌⢛⢷⣼⡇⢕⠨⢢⡿⣫⣢⡑⢌⠢⡊⢝⣧⠀⠀⠀⠀⠀⠀⢿⡯⣿⢽⡯⣿⢽⡯⣟⡷⣯⢷⣻⣽\n' +
            '⠀⠀⠀⠀⠀⣾⢃⢕⣬⣪⣢⣕⣌⣆⢑⠔⡹⡑⢔⠡⡑⣼⣿⣿⣿⡆⢕⠨⡂⢝⣦⣄⣀⣀⣀⣀⣿⣻⡽⣯⢿⢽⠯⢛⠽⢯⢹⠫⠻⢺\n' +
            '⠀⠀⠀⠀⢸⡿⡐⢌⠕⡩⠩⣩⣽⣵⡐⡑⠔⢌⠢⡑⢌⠺⢿⣿⡿⡃⠆⠕⢌⠢⡊⢌⠍⡍⡫⠹⡙⡛⡻⠿⢿⣟⣇⠻⢀⡒⢸⡻⠍⣼\n' +
            '⠀⠀⠀⠀⠈⣿⡈⡢⢑⠌⣺⣿⣿⣿⣿⢈⢊⠢⡑⢌⠢⡑⢅⠢⡑⢌⠪⡘⢔⠡⡊⢔⢑⠔⢌⠪⡐⢅⠪⡨⢂⠪⡙⠻⢿⣯⣼⢾⣺⣯\n' +
            '⠀⠀⠀⠀⠀⣻⡆⡊⡢⡑⠔⡻⠿⡿⡋⡢⢡⢑⢌⠢⡑⢌⠢⡑⢌⠢⡑⢌⠢⡑⢌⠢⠢⡑⢅⠪⡐⢅⠪⡐⢅⠕⢌⢊⠢⡙⡻⣿⣳⣯\n' +
            '⠀⠀⠀⣠⡾⠫⡨⡂⡒⢌⠪⡐⡑⠔⢌⠢⡑⢔⠰⡑⢌⠢⡑⢌⠢⡑⢌⠢⡑⢌⠢⡑⡑⢌⠢⡑⢌⠢⡑⢌⠢⡡⡑⡌⡌⡢⢊⢙⢿⣾\n' +
            '⠀⠀⣴⢏⠪⠨⡂⡪⠨⡂⢕⠨⡨⢊⠢⡑⢌⠢⡑⢌⠢⡑⢌⠢⡑⢌⠢⡑⢌⠢⡑⢌⢌⠢⡑⢌⠢⡑⢌⢢⣧⡢⠺⠿⡂⡊⡢⢡⢙⣿\n' +
            '⠀⣼⠇⠕⢌⠪⡐⢌⠪⡐⠅⢕⠨⠢⡑⢌⠢⡑⢌⠢⡑⢌⠢⡑⢌⠆⡃⣊⠢⡑⢌⠢⢢⢑⢌⠢⡑⢌⠢⡑⠝⢌⠪⡘⡐⢅⢊⠢⣢⣺\n' +
            '⢰⣏⠪⡘⢔⢑⢌⠢⡑⢌⢊⠢⡑⡑⢌⠢⡑⢌⠢⡑⢌⠢⡑⢌⠢⡑⢌⠢⡑⢌⠢⡑⢅⠢⠢⡑⢌⠢⡑⢌⢊⠢⡑⣌⣬⡶⠷⣿⢋⠭\n' +
            '⣻⠢⡑⢌⠢⠢⠢⡑⢌⠢⡡⡑⢌⢌⠢⡑⢌⠢⡑⢌⠢⡑⢌⠢⡑⢌⠢⡑⢌⠢⡑⢌⠢⡑⡑⡌⡢⣑⣬⣦⢷⣿⣟⠉⠁⠀⢸⡗⣐⢱\n' +
            '⣿⢑⢌⠢⡑⡑⡑⢌⠢⡑⠔⡌⡢⠢⡑⢌⠢⡑⢌⠢⡑⢌⠢⡑⢌⠢⡑⢌⠢⡑⢌⣢⣱⣼⠶⣷⣟⠋⠁⠀⢰⡗⡩⠻⢶⣴⠿⡑⡐⣽\n' +
            '⢺⡅⡢⡑⢌⢌⢌⠢⡑⢌⠪⡐⢌⠪⠨⠢⡑⢌⠢⡑⡌⡢⢱⢘⣐⣕⣬⡶⠷⣿⣟⠉⠁⠀⠀⣿⢙⠷⣦⣄⣾⢃⠪⡘⡐⡄⠕⢌⣾⣿\n' +
            '⠘⣷⡁⡪⠰⡐⡢⡑⢌⠢⡑⢌⠢⡑⡑⡑⢌⠪⡻⢳⠻⢿⡟⠋⠉⠉⠀⠀⢐⣟⢙⢷⣄⡀⣸⡏⠔⢌⠢⡩⡑⢌⢌⠢⡑⢌⣮⢟⣡⣿\n' +
            '⠀⠘⣷⡨⢊⢔⠰⡨⠢⡑⢌⠢⡑⢌⠬⠨⠢⡑⢌⠢⡡⡑⠝⢷⣄⠀⠀⢀⣾⢃⠆⢅⢍⢛⠝⡨⢊⠢⡑⠔⢌⠢⡢⣱⣼⡿⢟⢛⢹⣿\n' +
            '⠀⠀⠈⠻⣶⡐⢅⠪⡨⠨⠢⡑⢌⠢⡑⡑⡑⢌⠢⡑⠔⢌⢊⠢⡙⡻⠾⡛⡑⢔⠡⡑⢔⠡⡊⢔⠡⣑⣌⣮⡶⢟⠏⡫⠨⢌⠢⡑⢼⣿\n' +
            '⠶⡗⡟⡻⡛⡻⢷⣧⣮⣘⣌⡌⡢⡑⢌⢌⠌⢆⠕⢌⢊⠢⡡⡑⢌⠢⡑⢌⢌⠢⡑⢌⠢⡑⡼⡶⢟⠫⡋⢌⠢⡑⢌⢂⠣⡡⡑⣌⣪⣿\n' +
            '⡱⡱⡱⡑⡕⡕⢕⢜⢜⢝⢛⣿⢛⠟⡛⢗⠻⡃⢜⢐⠅⢕⠰⡨⠢⡑⢌⠢⠢⡑⢌⠢⡑⢌⢂⠪⡐⢅⢊⢢⣑⣌⣦⣵⡵⡾⡞⡻⡙⡜\n'
        );
        // eslint-disable-next-line
        console.log(
          '%c미리캔버스는 %cTypeScript, %cNextJs, %cMFA, %cJotai, %cturborepo, %cNest, %cpuppeteer, %cvitest, %cplaywright, %cdatadog, %cReact Query, %cStoryBook, %cstyled-components %c그리고 혁신적인 아이디어로 완성되었습니다.',
          'font-weight: normal; color: #fff; background-color: black; font-family: monospace;',
          'font-size: 15px; font-weight: bold; font-family: monospace;  color: #027ACB; ',
          'font-size: 15px; font-weight: bold; color: #fff; text-shadow: 1px 1px 0 black, -1px 1px 0 black, 1px -1px 0 black, -1px -1px 0 black;',
          'font-size: 15px; font-weight: bold; font-family: monospace;  color: pink; ',
          'font-size: 15px; font-weight: bold; font-family: monospace;  color: #585858; text-shadow: 1px 1px 0 #fff, -1px 1px 0 #fff, 1px -1px 0 #fff, -1px -1px 0 #fff;',
          'font-size: 15px; font-weight: bold; font-family: monospace;  color: #721D37; ',
          'font-size: 15px; font-weight: bold; font-family: monospace;  color: #E12D53; ',
          'font-size: 15px; font-weight: bold; font-family: monospace;  color: #1DDAA6; ',
          'font-size: 15px; font-weight: bold; font-family: monospace;  color: #FCCA36; text-shadow: 1px 1px 0 #769E25, -1px 1px 0 #769E25, 1px -1px 0 #769E25, -1px -1px 0 #769E25;',
          'font-size: 15px; font-weight: bold; font-family: monospace;  color: #37B03A; ',
          'font-size: 15px; font-weight: bold; font-family: monospace;  color: #734DDB; ',
          'font-size: 15px; font-weight: bold; font-family: monospace;  color: #FE485B; ',
          'font-size: 15px; font-weight: bold; font-family: monospace;  color: #FF4785; ',
          'font-size: 15px; font-weight: bold; font-family: monospace;  color: #E58F85; ',
          'font-weight: normal; color: #fff; background-color: black; font-family: monospace;'
        );
        // eslint-disable-next-line
        console.log(
          '%c미리캔버스에서 일하고 싶으신가요? 우리팀에 합류하고 싶다면 %chttps://miridih.career.greetinghr.com/ %c를 클릭하세요.',
          'font-size: 15px; font-weight: bold; font-family: monospace; color: #fff; background-color: black;',
          'font-size: 15px; font-weight: bold; font-family: monospace; color: #21afbf;',
          'font-size: 15px; font-weight: bold; font-family: monospace; color: #fff; background-color: black;'
        );
        console.groupEnd();
      }, 3000);
    }
  }, []);

  return null;
};
