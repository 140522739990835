import dynamic, { DynamicOptions } from 'next/dynamic';
import { ComponentType } from 'react';

/**
 * 주요하지 않은 컴포넌트의 로드를 늦춰 주요 컴포넌트의 로드를 먼저 진행하도록 할 수 있습니다.
 * @param loaderFn 컴포넌트를 로드하는 함수
 * @param dynamicOptions next/dynamic의 옵션
 * @param timeoutMs requestIdleCallback의 timeout 옵션
 */
export const dynamicIdle = <Props extends object>(
  loaderFn: () => Promise<ComponentType<Props>>,
  dynamicOptions?: DynamicOptions<Props>,
  timeoutMs?: number
) =>
  dynamic<Props>(
    () =>
      new Promise<ComponentType<Props>>((resolve) =>
        (window.requestIdleCallback || requestIdleCallbackPolyfill)(
          () => resolve(loaderFn()),
          timeoutMs ? { timeout: timeoutMs } : {}
        )
      ),
    {
      ...dynamicOptions,
      // web api를 사용하기 때문에 반드시 false로 지정합니다.
      ssr: false,
    }
  );

/**
 * IOS에서 requestIdleCallback이 지원되지 않는 경우를 대비한 폴리필 함수입니다.
 * https://developer.chrome.com/blog/using-requestidlecallback?hl=ko#checking_for_requestidlecallback
 */
const requestIdleCallbackPolyfill = (callback: IdleRequestCallback, _: IdleRequestOptions) => {
  const start = Date.now();

  return setTimeout(() => {
    callback({
      didTimeout: false,
      timeRemaining: function () {
        return Math.max(0, 50 - (Date.now() - start));
      },
    });
  }, 1);
};
