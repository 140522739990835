import { useSetAtom } from 'jotai';
import { atom, useAtomValue } from 'jotai/index';
import { getRootStore } from '@configs/jotai/RootProvider';
import { PhotoExtraEditToolInfoType } from '@ui/design/_common/_type/photoExtraEditToolType';
import { PhotoExtraEditorInitialImageInfo } from '@ui/design/desktop/photo_extra_edit/photo_extra_editor/_types/PhotoExtraEditorType';

type PhotoExtraEditorOpenInfo = Nullable<{
  initialToolInfo: PhotoExtraEditToolInfoType;
  itemId: string;
  initialImageInfo: PhotoExtraEditorInitialImageInfo;
}>;

export const photoExtraEditorOpenInfoAtom = atom<PhotoExtraEditorOpenInfo>(null);

export const isOpenPhotoExtraEditorSnapshotValue = (itemId: string) =>
  getRootStore().get(photoExtraEditorOpenInfoAtom)?.itemId === itemId;

export const useSetPhotoExtraEditorOpenInfo = () => useSetAtom(photoExtraEditorOpenInfoAtom);
export const usePhotoExtraEditorOpenInfo = () => useAtomValue(photoExtraEditorOpenInfoAtom);
