const ROOT = 0;
const MODAL_CONTAINER = ROOT + 1;
const TOOLTIP_CONTAINER = MODAL_CONTAINER + 1;
const ONBOARDING_TOOLTIP_CONTAINER = MODAL_CONTAINER;
const BOTTOM_SHEET_CONTAINER = ROOT + 1;
const FLOATING_BUTTON = ROOT + 1;
const EDITOR_BANNER = ROOT + 1;
const SNACK_BAR = TOOLTIP_CONTAINER + 1;

export const CommonZIndex = {
  ROOT,
  MODAL_CONTAINER,
  TOOLTIP_CONTAINER,
  BOTTOM_SHEET_CONTAINER,
  ONBOARDING_TOOLTIP_CONTAINER,
  FLOATING_BUTTON,
  EDITOR_BANNER,
  SNACK_BAR,
} as const;
