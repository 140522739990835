import { BuildEnv } from '@configs/environments/BuildEnv';
import { useEventListener } from '@utils/hooks';
import { useEffect, useRef } from 'react';
import { match, P } from 'ts-pattern';

const PERFORMANCE_ENTRY_TYPE = {
  LARGEST_CONTENTFUL_PAINT: 'largest-contentful-paint',
  LAYOUT_SHIFT: 'layout-shift',
};

type LayoutShiftRect = {
  bottom: number;
  height: number;
  left: number;
  right: number;
  top: number;
  width: number;
  x: number;
  y: number;
};

type LayoutShiftEntry = PerformanceEntry & {
  hadRecentInput?: boolean;
  value?: number;
  sources?: { node: Node; currentRect: LayoutShiftRect; previousRect: LayoutShiftRect }[];
};

type CoreWebVitalPerformanceStatus = 'GOOD' | 'NEEDS_IMPROVEMENT' | 'POOR';

const CLS_THRESHOLD = [0.1, 0.25];
const LCP_THRESHOLD = [2500, 4000];

const getVitalStatus = (score: number, threshold: number[]): CoreWebVitalPerformanceStatus => {
  return match<number, CoreWebVitalPerformanceStatus>(score)
    .with(P.number.lte(threshold[0]), () => 'GOOD')
    .with(P.number.lte(threshold[1]), () => 'NEEDS_IMPROVEMENT')
    .otherwise(() => 'POOR');
};

const _PerformanceTracker = () => {
  const clsEntries = useRef<LayoutShiftEntry[]>([]);
  const lcpEntries = useRef<PerformanceEntry[]>([]);

  const performanceObserver = useRef<PerformanceObserver | null>(null);

  /**
   * https://developer.mozilla.org/en-US/docs/Web/API/LargestContentfulPaint
   */
  const trackLargestContentfulPaint = (entries: PerformanceEntryList) => {
    if (!entries.length) return;

    const lastEntry = entries.at(-1);
    lastEntry && lcpEntries.current.push(lastEntry);
  };

  /**
   * @experimental
   * https://developer.mozilla.org/en-US/docs/Web/API/LayoutShift
   */
  const trackLayoutShift = (entries: PerformanceEntryList) => {
    if (!entries.length) return;

    for (const entry of entries as LayoutShiftEntry[]) {
      // Count layout shifts without recent user input only
      if (!entry?.hadRecentInput) {
        clsEntries.current.push(entry);
      }
    }
  };

  useEventListener('load', () => {
    const lcpEntry = lcpEntries.current.at(-1);
    const clsValue = clsEntries.current.reduce((acc, entry) => acc + (entry.value ?? 0), 0);

    console.group('[Performance]');
    lcpEntry &&
      console.log(
        `[Performance] - LCP(${getVitalStatus(lcpEntry.startTime, LCP_THRESHOLD)}) :`,
        `${lcpEntry.startTime}ms`,
        lcpEntry
      );
    console.log(
      `[Performance] - CLS(${getVitalStatus(clsValue, CLS_THRESHOLD)}) : ${clsValue.toFixed(3)}`,
      ...clsEntries.current
    );
    console.groupEnd();

    performanceObserver.current?.disconnect();
  });

  useEffect(() => {
    if (!window.PerformanceObserver || performanceObserver.current) return;

    performanceObserver.current = new PerformanceObserver((list) => {
      trackLargestContentfulPaint(
        list.getEntriesByType(PERFORMANCE_ENTRY_TYPE.LARGEST_CONTENTFUL_PAINT)
      );
      trackLayoutShift(list.getEntriesByType(PERFORMANCE_ENTRY_TYPE.LAYOUT_SHIFT));
    });

    Object.values(PERFORMANCE_ENTRY_TYPE).forEach((type) => {
      performanceObserver.current?.observe({ type, buffered: true });
    });

    return () => {
      performanceObserver.current?.disconnect();
    };
  }, []);

  return null;
};

export const PerformanceTracker = () => (BuildEnv.IS_PRODUCTION ? null : <_PerformanceTracker />);
